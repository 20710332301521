export const pageTitle = 'Rollup premium';
export const mainHeader = 'Rollup reklamowy premium';
export const title = 'Rollup premium';

export const rollupInfo = {
  icon: true,
  priceList: true,
  leftColumn: [
    {
      article: `**Rollup <span class="orange">Czas realizacji</span>**
      \n ok. 1-2 dni robocze`,
    },
    {
      article: `**Rollup <span class="orange">koszt wysyłki</span>**
      \n 20,00 zł/netto
      `,
    },
  ],
  rightColumn: [
    {
      article: `**Rollup <span class="orange">reklamowy</span>**
      \n Roll-up o wyjątkowo eleganckim i profesjonalnym wyglądzie z kasetą o opływowym kształcie tzw. “łezki”. Niezwykle prestiżowy i bardzo stabilny spełniające
      najwyższe oczekiwania Klientów. Stworzony na okazałe eventy i doniosłe spotkania biznesowe.
      \n * wyjątkowo elegancki wygląd,
      \n * listwa typu „Express Clip“ – błyskawiczny i łatwy montaż oraz wymiana wydruku,
      \n * kaseta w całości wykonana z wzmocnionego
      \n * aluminium anodowanego,
      \n * duża stabilność systemu.
      `,
    },
    {
      article: `**Rollup z wydrukiem <span class="orange">specyfikacja</span>**
      \n * wysokość rozłożonego systemu: 205cm
      \n * waga systemu wraz z grafiką: ok. 5 - 9 kg (w zależności od szerokości kasety)
      \n * w zestawie: kaseta, maszt z uchwytem, listwa, wydrukowana grafika i torba transportowa
      \n Wydruk wykonany metodą mild solwent na materiale dedykowanym do systemów wystawienniczych - Blockout.
      `,
    },
    {
      article: `**Rollup <span class="orange">wymiary</span>**
      \n * ${title} 85x200cm
      \n * ${title} 100x200cm
      \n * ${title} 120x200cm
      \n * ${title} 150x200cm
      \n * ${title} 200x200cm
      `,
    },
    {
      article: `**Tani rollup <span class="orange">zastosowanie</span>**
      \n * na prezentacjach,
      \n * na konferencjach,
      \n * na spotkaniach biznesowych,
      \n * w biurach,
      \n * na eventach,
      \n * w punktach sprzedaży.
      `,
    },
    {
      article: `**Tanie rollupy <span class="orange">zalety</span>**
      \n * profesjonalny i elegancki wygląd,
      \n * wygodny transport,
      \n * łatwość w rozkładaniu i składaniu,
      \n * znany i sprawdzony,
      \n * ekonomiczny.
      `,
    },
    {
      article: `**Rollup <span class="orange">projekt graficzny</span>**
      \n Jeśli potrzebują Państwo wykonać projekt graficzny zachęcamy do skorzystania z naszych usług.
      `,
    },
  ],
};
